<template>
  <div class="home">
    <div class="block block1">
      <v-parallax src="../assets/images/home.png" :height="$vuetify.breakpoint.height * .8">
        <v-container>
          <v-row>
            <v-col class="text-center text-md-left black--text" cols="12" md="8">
              <h1 class="text-h2 font-weight-bold mb-4 mt-10 d-none d-md-block" v-html="$t('home.block1.header')"></h1>
              <h1 class="text-h3 font-weight-bold mb-4 d-md-none" v-html="$t('home.block1.header')"></h1>
              <h3 class="text-h4 mb-6" v-html="$t('home.block1.subheader')"></h3>
              <v-btn color="primary" dark rounded large @click="contactUsDialog=true" class="cta">
                {{ $t('home.block1.cta') }}
                <v-icon class="ml-3">mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
              <div class="scroller-spacer d-none"></div>
            </v-col>
            <v-col cols="4" class="d-none d-md-block"></v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </div>
    <div class="block block2 py-15 px-5 container">
      <h2 class="text-center text-h4 font-weight-bold pb-10">
        {{ $t('home.block2.header') }}
      </h2>
      <v-row>
        <v-col cols="12" md="6" lg="3" class="text-center icon-box px-6">
          <div class="mb-2 icon">
            <v-icon size="80" color="primary">mdi-magnify-scan</v-icon>
          </div>
          <div class="text-h6 title" v-html="$t('home.block2.types.0.name')"></div>
          <div class="body-1 text-justify description" v-html="$t('home.block2.types.0.text')"></div>
          <div class="cta">
            <v-btn color="secondary" small class="mt-4 mb-5"
                   :to="{name: 'practice-areas', params: {locale: $i18n.locale, anchor: Object.keys($t('practice-areas.sections') || {})[0] || ''}, query: {}}">
              {{ $t('home.block2.more') }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="text-center icon-box px-6">
          <div class="mb-2 icon">
            <v-icon size="80" color="primary">mdi-format-list-checks</v-icon>
          </div>
          <div class="text-h6 title" v-html="$t('home.block2.types.1.name')"></div>
          <div class="body-1 text-justify description" v-html="$t('home.block2.types.1.text')"></div>
          <div class="cta">
            <v-btn color="secondary" small class="mt-4 mb-5"
                   :to="{name: 'practice-areas', params: {locale: $i18n.locale, anchor: Object.keys($t('practice-areas.sections') || {})[1] || ''}, query: {}}">
              {{ $t('home.block2.more') }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="text-center icon-box px-6">
          <div class="mb-2 icon">
            <v-icon size="80" color="primary">mdi-stop-circle-outline</v-icon>
          </div>
          <div class="text-h6 title" v-html="$t('home.block2.types.2.name')"></div>
          <div class="body-1 text-justify description" v-html="$t('home.block2.types.2.text')"></div>
          <div class="cta">
            <v-btn color="secondary" small class="mt-4 mb-5"
                   :to="{name: 'practice-areas', params: {locale: $i18n.locale, anchor: Object.keys($t('practice-areas.sections') || {})[2] || ''}, query: {}}">
              {{ $t('home.block2.more') }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="text-center icon-box px-6">
          <div class="mb-2 icon">
            <v-icon size="80" color="primary">mdi-eye-lock</v-icon>
          </div>
          <div class="text-h6 title" v-html="$t('home.block2.types.3.name')"></div>
          <div class="body-1 text-justify description" v-html="$t('home.block2.types.3.text')"></div>
          <div class="cta">
            <v-btn color="secondary" small class="mt-4 mb-5"
                   :to="{name: 'practice-areas', params: {locale: $i18n.locale, anchor: Object.keys($t('practice-areas.sections') || {})[3] || ''}, query: {}}">
              {{ $t('home.block2.more') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="block block3 py-15 px-5 white--text">
      <h2 class="text-center text-h4 font-weight-bold pb-10">
        {{ $t('home.block3.header') }}
      </h2>
      <v-container style="max-width: 800px">
        <v-row class="text-center text-h2 font-weight-regular" justify="center" align="center">
          <v-col cols="12" md="3" class="pb-1 pb-md-3">
            {{ $t('home.block3.numbers.0.number') }}
          </v-col>
          <v-col cols="12" class="d-md-none text-h6 font-weight-regular pt-0 pb-5">
            {{ $t('home.block3.numbers.0.title') }}
          </v-col>
          <v-col cols="1" class="d-none d-md-block text-h4">
            &#x2022;
          </v-col>
          <v-col cols="12" md="3" class="pb-1 pb-md-3">
            {{ $t('home.block3.numbers.1.number') }}
          </v-col>
          <v-col cols="12" class="d-md-none text-h6 font-weight-regular pt-0 pb-5">
            {{ $t('home.block3.numbers.1.title') }}
          </v-col>
          <v-col cols="1" class="d-none d-md-block text-h4">
            &#x2022;
          </v-col>
          <v-col cols="12" md="3" class="pb-1 pb-md-3">
            {{ $t('home.block3.numbers.2.number') }}
          </v-col>
          <v-col cols="12" class="d-md-none text-h6 font-weight-regular pt-0 pb-5">
            {{ $t('home.block3.numbers.2.title') }}
          </v-col>
        </v-row>
        <v-row class="text-center text-h6 font-weight-regular d-none d-md-flex mt-0" justify="center">
          <v-col cols="3">
            {{ $t('home.block3.numbers.0.title') }}
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="3">
            {{ $t('home.block3.numbers.1.title') }}
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="3">
            {{ $t('home.block3.numbers.2.title') }}
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="block block4">
      <v-row class="mx-0">
        <v-col cols="12" md="8" class="px-0">
          <div class="py-15 px-8">
            <h2 class="text-center text-h4 font-weight-bold pb-10">
              {{ $t('home.block4.header_1') }}
            </h2>
            <v-row justify="center">
              <v-col cols="12" lg="4" class="text-center type icon-box px-5"
                     v-for="(c, i) in ($t('home.block4.content_1') || [])" :key="`h-b4-${i}`">
                <div v-if="i !== 0" class="text-center d-lg-none">
                  <v-icon size="50" class="grey--text text--lighten-1 px-0 pb-3">
                    mdi-chevron-down
                  </v-icon>
                </div>
                <div class="d-flex align-center">
                  <div v-if="i !== 0" style="width: 0; overflow: visible;"
                       class="d-none d-lg-block">
                    <v-icon size="50" class="grey--text text--lighten-1 px-0 ml-n11">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div class="flex-fill">
                    <div class="mb-2 icon">
                      <v-icon size="80" color="primary">{{ c.icon }}</v-icon>
                    </div>
                    <div class="title font-weight-bold text-h6 title-btn primary--text text-uppercase">
                      {{ c.title }}
                    </div>
                  </div>
                </div>
                <div class="body-1 text-justify description" v-html="c.text"></div>
              </v-col>
            </v-row>
          </div>
          <div class="pb-15 px-8">
            <h2 class="text-center text-h4 font-weight-bold pb-10">
              {{ $t('home.block4.header_2') }}
            </h2>
            <v-row justify="center">
              <v-col cols="12" md="6" lg="3" class="text-center type px-6 icon-box"
                     v-for="(c, i) in ($t('home.block4.content_2') || [])" :key="`h-b4-${i}`">
                <div class="mb-2 icon">
                  <v-icon size="80" color="primary">{{ c.icon }}</v-icon>
                </div>
                <div class="title font-weight-bold text-h6 title-btn primary--text text-uppercase">
                  {{ c.title }}
                </div>
                <div class="body-1 text-justify description" v-html="c.text"></div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4" class="d-none d-md-block px-0">
          <v-parallax src="../assets/images/home2.png" style="height: 100% !important;"></v-parallax>
        </v-col>
      </v-row>
    </div>
    <div class="block block5">
      <div class="py-15 px-2 px-md-7 container">
        <v-row class="mx-0">
          <v-col cols="12" md="6">
            <h2 class="text-center text-md-left text-h4 font-weight-bold pb-10">
              {{ $t('home.block5.header') }}
            </h2>
            <div class="content px-5 px-md-4 text-justify" v-html="$t('home.block5.content')"></div>
          </v-col>
          <v-col cols="12" md="6" class="px-5">
            <contact-form context="home"></contact-form>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="contactUsDialog" width="500" persistent>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('home.contact-us_dialog.header') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="contactUsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <contact-form context="home-dialog" @sent="delayCloseContactUs"></contact-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'Home',
  components: {ContactForm},
  data: () => ({
    contactUsDialog: false,
  }),
  methods: {
    delayCloseContactUs() {
      setTimeout(() => {
        if (this.contactUsDialog) {
          this.contactUsDialog = false;
        }
      }, 3000);
    }
  }
}
</script>

<style lang="scss">

div.icon-box {
  display: flex;
  flex-direction: column;

  div.title {
    line-height: 1.55rem;
    padding-bottom: 0.7rem;
  }

  div.description {
    align-self: center;
    max-width: 300px;
    flex-grow: 2;
  }
}

div.block3 {
  background: #2D2B70;
}

div.block4 {
  button.title-btn {
    span.v-btn__content {
      opacity: 1 !important;
    }
  }
}

div.block5 {
  background: #F7F0FE;
}
</style>