<template>
  <div class="page text-left px-2">
    <page-title class="text-center" :text="$t('identify-violationsPage.title')"></page-title>
    <v-container>

      <div class="mt-6">
        {{ $t('identify-violationsPage.listTop') }}
      </div>
      <div>
        <ul>
          <li>
            <strong>{{ $t('identify-violationsPage.detection-copyright.title') }}</strong> - {{ $t('identify-violationsPage.detection-copyright.text') }}
          </li>
          <li>
            <strong>{{ $t('identify-violationsPage.brand-protection.title') }}</strong> - {{ $t('identify-violationsPage.brand-protection.text') }}
          </li>
          <li>
            <strong>{{ $t('identify-violationsPage.predictive-analytics.title') }}</strong> - {{ $t('identify-violationsPage.predictive-analytics.text') }}
          </li>
        </ul>
      </div>
      <div class="mt-6 text-justify">
        {{ $t('identify-violationsPage.listBottom') }}
      </div>
    </v-container>
  </div>
</template>

<script>

import PageTitle from "@/components/PageTitle.vue";

export default {
  name: 'BasicPage',
  components: {PageTitle},
}
</script>

<style lang="scss">
ul {
  padding: 5px 0 5px 30px;
  list-style: none;

  > li {
    padding: 5px 0 5px 10px;
    text-align: justify;

    &::before {
      content: "\2022";
      color: #9E3FFD;
      font-weight: bold;
      display: inline-block;
      width: 20px;
      margin-left: -20px;
    }

    > strong {
      display: inline-block;
      color: #9E3FFD;
      text-align: left;
    }
  }
}
</style>