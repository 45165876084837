<template>
  <v-app v-bind:class="{'mobile': !$vuetify.breakpoint.mdAndUp, 'permanent-menu': this.permanentMenu}">
    <div class="bg-scroll-color-fix"></div>
    <v-navigation-drawer v-if="!$vuetify.breakpoint.mdAndUp"
                         app temporary color="#FFFFFF"
                         height="100%" :width="300"
                         v-model="menuOpen">
      <div class="mobile-menu-top pa-2 text-right">
        <v-btn text plain @click="menuOpen=false" style="min-width: 36px;">
          <v-icon color="#000000">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-list class="mobile-menu">
        <v-list-item v-for="item in menu"
                     :key="item.title"
                     :to="{name: item.route, params: {locale: $i18n.locale}, query: {}}"
                     link>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 text-center">
          <div class="copyright mt-1" v-html="$t('copyright')"></div>
        </div>
      </template>
    </v-navigation-drawer>

    <TopNavigation @openNavigationDrawer="menuOpen=true"></TopNavigation>

    <v-main>
      <v-container fluid class="main-content-wrapper pa-0">
        <router-view v-on:setPermanentMenu="permanentMenu=$event"></router-view>
      </v-container>
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import {menuLeft, menuRight} from '@/modules/navigation';
import TopNavigation from "@/components/TopNavigation";
import Footer from "@/components/Footer";

export default {
  name: 'App',

  components: {
    Footer,
    TopNavigation,
  },

  watch: {
    '$route': {
      handler(v) {
        document.title = this.$t(`page_title.${v.name}`) + ((v.name !== 'home') ? (' - ' + this.$t(`page_title.home`)) : '');
      },
      immediate: true,
    }
  },

  data: () => ({
    menu: [
      ...menuLeft,
      ...menuRight,
    ],
    permanentMenu: false,
    menuOpen: false,
  }),
};
</script>

<style lang="scss" scoped>
@import "scss/variables";

.v-application {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  letter-spacing: .03em;
  background-color: #ffffff !important;
  color: #000000;
  cursor: default;
}
.mobile-menu-top {
  height: $main-menu-mobile-height;
}
.mobile-menu {
  > a.v-list-item {

    &.v-list-item--active {
      font-weight: bold;
      color: $active-link-color;
      background: white;
    }
  }
}
</style>
