<template>
  <div class="footer pa-2">
    <v-container class="pb-3">
      <v-row>
        <v-col>
          <div class="copyright mt-1" v-html="$t('copyright')"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  props: {},

  data: () => ({}),

  methods: {}
}
</script>

<style lang="scss" scoped>
div.footer {
  background-color: #2D2B70;
  color: #FFFFFF;

  div.copyright {
    text-align: center;
  }
}
</style>