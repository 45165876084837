import Home from "@/views/Home.vue";
import BasicPage from "@/views/IntellectualPropertyPage.vue";
import WhyProtect from "@/views/WhyProtectPage.vue";
import ViolationsPage from "@/views/ViolationsPage.vue";
import IdentifyViolationsPage from "@/views/IdentifyViolationsPage.vue";
import PracticeAreasPage from "@/views/PracticeAreasPage.vue";
import Error404 from "@/views/404.vue";

export const defaultLocale = 'en';

export const locales = require.context("./locales", true, /([A-Za-z0-9-_,\s]+)\.json$/i).keys()
  .map(key => key.match(/([A-Za-z0-9-_]+)\./i)[1])
  .filter(key => key.length > 1);

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: Home
  },
  {
    path: '/intellectual-property',
    name: 'intellectual-property',
    component: BasicPage
  },
  {
    path: '/types-of-violations',
    name: 'violations',
    component: ViolationsPage
  },
  {
    path: '/identify-violations',
    name: 'identify-violations',
    component: IdentifyViolationsPage
  },
  {
    path: '/practice-areas/:anchor?',
    name: 'practice-areas',
    props: (route) => ({anchor: (route.params.anchor || '').toString()}),
    component: PracticeAreasPage
  },
  {
    path: '/why-protect',
    name: 'why-protect',
    component: WhyProtect
  },
  {
    path: '/temp',
    name: 'temp',
    component: Error404
  },
];