import Vue from 'vue'
import VueRouter from 'vue-router'
import {browserLocale, default as i18n} from './i18n'
import {locales, routes} from '@/config';
import Error404 from "@/views/404.vue";

Vue.use(VueRouter);

const prefix = '/:locale(' + locales.join('|') + ')';

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'redirect',
      redirect: `/${browserLocale}`,
    },
    ...routes.map(m => {
      return {...m, path: prefix + m.path};
    }),
    {
      path: `*`,
      name: '404',
      component: Error404
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({name: '404'});
    return;
  }
  if (to.params.locale && to.params.locale !== i18n.locale) {
    i18n.locale = to.params.locale;
  }
  next();
})

export default router;