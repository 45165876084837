export const menuLeft = [
  {
    title: "head.item1",
    route: "intellectual-property"
  },
  {
    title: "head.item2",
    route: "why-protect"
  },
];

export const menuRight = [
  {
    title: "head.item3",
    route: "violations"
  },
  {
    title: "head.item4",
    route: "identify-violations"
  },
];