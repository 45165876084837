<template>
  <div class="menu" ref="menu" v-bind:class="{'mobile': mobile}" v-scroll="onScroll">
    <v-container :fluid="!this.$vuetify.breakpoint.lgAndUp" class="pb-md-0 py-1 py-md-2">
      <v-row dense align="center" justify="center">
        <v-col cols="4" md="1" class="text-left">
          <v-btn v-if="mobile" text plain @click="$emit('openNavigationDrawer')">
            <v-icon color="#000000">mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <template v-if="!mobile">
          <v-col cols="2" class="top-menu-base text-center"
                 v-for="item in menuLeft"
                 :key="item.title">
            <v-btn text plain active-class="accent--text" :ripple="false" class="text-wrap"
                   :to="{name: item.route, params: {locale: $i18n.locale}, query: {}}">
              {{ $t(item.title) }}
            </v-btn>
          </v-col>
        </template>
        <v-col cols="4" md="2" class="logo-base">
          <div>
            <router-link v-if="mobile" class="logo top-menu-mobile"
                         :to="{name: 'home', params: {locale: $i18n.locale}, query: {}}"></router-link>
            <router-link v-else class="logo top-menu"
                         :to="{name: 'home', params: {locale: $i18n.locale}, query: {}}"></router-link>
            <div class="logo-bg"></div>
            <div class="logo-bg2"></div>
          </div>
        </v-col>
        <template v-if="!mobile">
          <v-col cols="2" class="top-menu-base text-center"
                 v-for="item in menuRight"
                 :key="item.title">
            <v-btn text plain active-class="accent--text" :ripple="false" class="text-wrap"
                   :to="{name: item.route, params: {locale: $i18n.locale}, query: {}}">
              {{ $t(item.title) }}
            </v-btn>
          </v-col>
        </template>
        <v-col cols="4" md="1" class="text-right">
          <v-menu close-on-click left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text plain v-bind="attrs" v-on="on">
                {{ crrLocale.short }}
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item v-for="(item) in localesList"
                           :key="`${item.short}-${$route.name}`"
                           :to="$route.name === '404' ? undefined : {name: $route.name, params: {...$route.params, locale: item.__key}, query: $route.query}"
                           @click="changeLocale(item.__key)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <div class="menu-bg"></div>
    <div class="menu-bg2"></div>
  </div>
</template>

<script>
import {menuLeft, menuRight} from '@/modules/navigation';
import {localesList} from "@/modules/i18n";

export default {
  name: 'TopNavigation',

  data: () => ({
    menuLeft: menuLeft,
    menuRight: menuRight,
  }),

  computed: {
    crrLocale: {
      get() {
        return this.localesList.filter(l => l.__key === this.$i18n.locale)[0];
      }
    },
    localesList: {
      get() {
        return localesList;
      }
    },
    mobile: {
      get() {
        return !this.$vuetify.breakpoint.mdAndUp;
      }
    }
  },

  methods: {
    onScroll() {
      let coef = window.innerHeight / (this.$route.name === 'home' ? 70 : 50);
      if (window.scrollY < (window.innerHeight / coef)) {
        this.$refs.menu.classList.remove('offset');
      } else {
        if (this.$refs.menu.classList.contains('offset') === false) {
          this.$refs.menu.classList.add('offset');
        }
      }
    },

    changeLocale(locale) {
      if (this.$route.name !== '404') {
        return;
      }
      this.$i18n.locale = locale;
    },
  },

  mounted() {
    this.onScroll();
  }
}
</script>

<style lang="scss" scoped>
div.menu {
  @import "../scss/variables";

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: $main-menu-height;
  background-color: transparent;

  &.mobile {
    height: $main-menu-mobile-height;
  }

  > div.container {
    height: 100%;

    > div.row {
      height: 100%;

      > div.top-menu-base a {
        color: black;
        font-size: 1.2rem;
        text-decoration: none;

        &.v-btn::v-deep {
          > span.v-btn__content {
            flex-shrink: 1;
          }

          &.v-btn--active {
            font-weight: bold;
            color: $active-link-color;
          }
        }
      }
    }
  }

  .logo-base {
    height: 100%;
    overflow: visible;
    text-align: center;

    > div {
      position: relative;
      display: inline-block;

      > div.logo-bg,
      > div.logo-bg2 {
        position: absolute;
        box-sizing: content-box;
        top: -50px;
        left: -20px;
        right: -20px;
        bottom: -10px;
        z-index: -1;
        border-radius: 0 0 100px 100px;
        background: rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 20px 15px rgba(255, 255, 255, 0.4);
        transition: background 0.1s ease-out 0.08s, border-bottom 0.1s ease-out 0.08s, opacity 0.1s ease-out 0.08s, border-bottom 0.1s ease-out, box-shadow 0.1s linear;
      }

      > div.logo-bg2 {
        z-index: -2;
        opacity: 0;
        padding: 1px;
        background: none;
        margin: -1px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
        border: 1px #DCDCDC solid;
        transition: all 0.2s ease-out;
      }
    }
  }

  > div.menu-bg,
  > div.menu-bg2 {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
  }

  > div.menu-bg2 {
    opacity: 0;
    background: #FFFFFF;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
    border-bottom: 0 transparent solid;
    transition: background 0.1s ease-out 0.08s, border-bottom 0.1s ease-out 0.08s, opacity 0.1s ease-out 0.08s, border-bottom 0.1s ease-out, box-shadow 0.1s linear;
  }

  &.offset {
    @import "../scss/menu_offset.scss";
  }
}

.permanent-menu {
  div.menu {
    @import "../scss/menu_offset.scss";
  }
}

</style>