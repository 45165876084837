import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/assets/scss/variables.scss'],
  treeShake: true,
  theme: {
    themes: {
      light: {
        primary: '#9E3FFD',
        secondary: '#2D2B70',
        accent: '#CC3333',
      },
    },
  },
});
