<template>
  <div class="page text-left px-2">
    <page-title class="text-center" :text="$t('why-protect.title')"></page-title>
    <v-container>

      <div class="mt-4">
        {{ $t('why-protect.intro') }}
      </div>

      <v-card class="mt-12 mx-auto" elevation="1">
        <v-sheet class="v-sheet--offset mx-auto" color="primary" elevation="5" max-width="calc(100% - 32px)"
                 style="position: relative; top: -25px;">
          <v-sparkline auto-draw height="140px" color="white" line-width="1" label-size="4" padding="16" smooth="3"
            :labels="$t('why-protect.chart.data.labels')"
            :value="$t('why-protect.chart.data.values')"
          ></v-sparkline>
        </v-sheet>

        <v-card-text class="pt-0 pb-2">
          <div class="body-1 font-weight-medium mb-4">
            {{ $t('why-protect.chart.description') }}
          </div>
          <v-divider class="mt-2 mb-3"></v-divider>
          <div class="text-caption grey--text font-weight-light d-inline-block">
            <v-icon class="mr-2 grey--text" x-small>mdi-asterisk</v-icon>
            {{ $t('why-protect.chart.info') }}
          </div>
        </v-card-text>
      </v-card>

      <div class="mt-10">
        {{ $t('why-protect.listTop') }}
      </div>
      <div>
        <ul>
          <li>
            <strong>{{ $t('why-protect.loss-of-revenue.title') }}</strong> - {{
              $t('why-protect.loss-of-revenue.text')
            }}
          </li>
          <li>
            <strong>{{ $t('why-protect.reputational-damage.title') }}</strong> -
            {{ $t('why-protect.reputational-damage.text') }}
          </li>
          <li>
            <strong>{{ $t('why-protect.legal-expenses.title') }}</strong> - {{ $t('why-protect.legal-expenses.text') }}
          </li>
          <li>
            <strong>{{ $t('why-protect.remedies-and-damages.title') }}</strong> -
            {{ $t('why-protect.remedies-and-damages.text') }}
          </li>
          <li>
            <strong>{{ $t('why-protect.competitive-harm.title') }}</strong> - {{
              $t('why-protect.competitive-harm.text')
            }}
          </li>
          <li>
            <strong>{{ $t('why-protect.lost-opportunities.title') }}</strong> -
            {{ $t('why-protect.lost-opportunities.text') }}
          </li>
        </ul>
      </div>
      <div class="mt-6 text-justify">

      </div>
    </v-container>
  </div>
</template>

<script>

import PageTitle from "@/components/PageTitle.vue";

export default {
  name: 'BasicPage',
  components: {PageTitle},

  data: () => ({})
}
</script>

<style lang="scss">
ul {
  padding: 5px 0 5px 30px;
  list-style: none;

  > li {
    padding: 5px 0 5px 10px;
    text-align: justify;

    &::before {
      content: "\2022";
      color: #9E3FFD;
      font-weight: bold;
      display: inline-block;
      width: 20px;
      margin-left: -20px;
    }

    > strong {
      display: inline-block;
      color: #9E3FFD;
      text-align: left;
    }
  }
}
</style>