<template>
  <v-form v-model="valid"
          class="form-base"
          ref="form"
          @submit="submit">
    <v-container>
      <v-row>
        <v-col class="text-left">
          <v-alert type="success" :class="{'d-none': !success}">
            Your message has been delivered!
          </v-alert>

          <v-alert type="error" outlined class="bg-white" :class="{'d-none': !error}">
            Message could not be delivered, please check that You have filled the form or try again later!
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="name"
                        :rules="nameRules"
                        color="primary"
                        :label="$t('contact-us.name')"
                        required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field v-model="email"
                        :rules="emailRules"
                        color="primary"
                        :label="$t('contact-us.email')"
                        required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea v-model="message"
                      :rules="messageRules"
                      color="primary"
                      :label="$t('contact-us.message')"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-right">
            <v-btn type="submit" :disabled="!valid" :loading="loading" outlined color="primary">
              {{ $t('contact-us.send') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'ContactForm',

  props: {
    context: {
      type: String,
      required: true
    }
  },

  data: () => ({
    success: false,
    error: false,
    valid: false,
    loading: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    message: '',
    messageRules: [
      v => !!v || 'Message is required',
    ],
  }),

  methods: {
    submit(e) {
      e.preventDefault();
      if (!this.valid) {
        this.$refs.form.validate();
        return false;
      }

      this.loading = true;
      this.axios({
        method: 'post',
        url: '/api.php',
        data: {
          context: this.context,
          name: this.name,
          email: this.email,
          message: this.message,
        }
      }).then((response) => {
        if (response.data.success) {
          this.success = true;
          this.error = false;
          this.name = '';
          this.email = '';
          this.message = '';
          this.$emit('sent')
        } else {
          this.success = false;
          this.error = true;
        }
      }).catch(() => {
        this.success = false;
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });

      return false;
    },
  }
}
</script>

<style lang="scss" scoped>
form.form-base {
  margin: auto;
  max-width: 700px;

  .v-btn {
    font-family: Roboto, sans-serif;
  }
}

div.v-alert.bg-white {
  background-color: #FFFFFF !important;
}
</style>