<template>
  <div class="error404 px-4">
    <h1 class="text-h2 font-weight-light pb-7">
      {{ $t('error404.title') }}
    </h1>
    <h3 class="text-h4 font-weight-light pb-10">
      {{ $t('error404.body') }}
    </h3>
    <div>
      <v-btn color="#CC3333" dark exact
             :to="{name: 'home', params: {locale: $i18n.locale}, query: {}}">
        {{ $t('error404.cta') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Error404',
    }
</script>

<style lang="scss">
.error404 {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>