<template>
  <div>
    <h1 class="text-h4">
      <slot name="content">
        {{ text }}
      </slot>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',

  props: {
    text: {
      type: String,
      required: false
    }
  },

  data: () => ({}),

  methods: {}
}
</script>

<style lang="scss" scoped>

</style>