import Vue from "vue";
import VueI18n from "vue-i18n";
import {defaultLocale, locales} from '@/config';

Vue.use(VueI18n);

function getBrowserLocale(options = {}) {
  let defaultOptions = {countryCodeOnly: false}
  let opt = {...defaultOptions, ...options}
  let languages = navigator.languages
    .map(l => opt.countryCodeOnly
      ? l.trim().split(/-|_/)[0]
      : l.trim())
    .filter(l => locales.includes(l));

  let navigatorLocale = languages[0] !== undefined ? languages[0] : navigator.language
  if (!navigatorLocale) {
    return defaultLocale
  }
  return navigatorLocale;
}

const messages = (() => {
  let messages = {};
  locales.forEach(l => {
    if (!messages[l]) {
      messages[l] = require(`@/locales/${l}.json`);
    }
  })
  return messages;
})();

export const browserLocale = getBrowserLocale({countryCodeOnly: true});
export const localesList = (() => {
  let meta = [];
  Object.keys(messages).forEach(v => {
    let m = (messages[v]['__meta'] || undefined);
    if (m) {
      m.__key = v;
      meta.push(m);
    }
  });
  return meta.sort((a,b) => (a.order || 0) - (b.order || 0));
})()
export default new VueI18n({
  locale: browserLocale,
  fallbackLocale: defaultLocale,
  messages: messages,
});