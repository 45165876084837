<template>
  <div class="page text-left px-2">
    <page-title class="text-center" :text="$t('practice-areas.title')"></page-title>
    <v-container>
      <div v-for="(section, key) in $t('practice-areas.sections')" :key="key" :id="key" class="mt-4">
        <page-title class="page-title" :class="{'current': anchor === key}">
          <template v-slot:content>
            <v-icon size="35" color="primary">{{ anchor === key ? 'mdi-check-all' : '' }}</v-icon>
            {{ section.title }}
          </template>
        </page-title>
        <div class="text-justify mt-2" v-html="section.html"></div>
      </div>
    </v-container>
  </div>
</template>

<script>

import PageTitle from "@/components/PageTitle.vue";
import Vue from "vue";

export default {
  name: 'BasicPage',
  components: {PageTitle},
  props: {
    anchor: {
      type: String,
      required: false,
      default: '',
    }
  },
  data: () => ({
    scrolled: false,
  }),
  watch: {
    anchor: {
      handler(v) {
        this.scrolled = false;
        this.scrollTo(v);
      },
      immediate: false,
    }
  },
  methods: {
    scrollTo(anchor) {
      if (!anchor || anchor.length === 0) {
        return;
      }
      let el = document.getElementById(anchor);
      if (!el) {
        return;
      }
      let y = el.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    }
  },
  mounted() {
    if (!this.scrolled) {
      Vue.nextTick().then(() => {
        this.scrollTo(this.anchor);
      });
    }
  }
}
</script>

<style lang="scss">
div.page-title {
  i {
    min-width: 35px;
  }

  &.current {
    color: #9E3FFD;
  }
}

ul {
  padding: 5px 0 5px 30px;
  list-style: none;

  > li {
    padding: 5px 0 5px 10px;
    text-align: justify;

    &::before {
      content: "\2022";
      color: #9E3FFD;
      font-weight: bold;
      display: inline-block;
      width: 20px;
      margin-left: -20px;
    }

    > strong {
      display: inline-block;
      color: #9E3FFD;
      text-align: left;
    }
  }
}
</style>